import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import _ from "lodash";
import Horarios from "./components/horarios";
import Videos from "./components/videos"; // 2021-01-15
import VideoPlayer from "./components/videoPlayer"; // 2021-01-15
import Covid from "./components/covid19"; // 2021-01-15
import PaginaInfo from "./components/paginaInfo"; // 2021-01-19
import ListGroupLinks from "./components/common/listGroupLinks";
import { getHorarios } from "./services/horarioService";
//import { getHorarios1 } from "./services/horario1Service"; //2023-03-10
import { getSalas } from "./services/salaService";
//import { getSalas1 } from "./services/sala1Service"; //2023-03-10
import { getCheckin } from "./services/presencaService"; // 2020-10-15
//import { getCheckin1 } from "./services/presenca1Service"; //2023-03-10
import { getVideos } from "./services/videoService"; // 2021-01-15 - Videos
//import { getVideos1 } from "./services/video1Service"; // 2023-03-10
import {
  getExibePaginaInfo,
  getVisitouPaginaInfo,
} from "./services/infoService"; // 2021-01-19
import { getNews } from "./services/newsService"; // 2022-09-01 - News
import { getNews1 } from "./services/news1Service"; // 2023-03-10
import News from "./components/news"; // 2022-09-04

import {
  postReserva,
  anulaReserva,
  confirmaReserva,
} from "./services/reservaService";
//import {
//  postReserva1,
//  anulaReserva1,
//  confirmaReserva1,
//} from "./services/reserva1Service"; //2023-03-10

import Start from "./components/start";
import LoginForm from "./components/loginForm";
import Logout from "./components/logout";
import auth from "./services/authService";
import RegisterForm from "./components/registerForm";
import NavBarNew from "./components/navbarNew";

import CodigoQR from "./components/CodigoQR"; //2023-02-21

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

Modal.setAppElement("#root"); //2020-05-22

//2021-04-06
const user = auth.getCurrentUser(); // 1. Is there a token in the storage?

class App extends Component {
  state = {
    periodos: [
      { _id: 0, name: "Hoje" },
      { _id: 1, name: "Amanhã" },
    ],
    salas: [],
    //selectedItem: "0",
    selectedItem:
      window.location.pathname === "/horarios/0"
        ? "0"
        : window.location.pathname === "/horarios/1"
        ? "1"
        : "0",
    horarios: [],
    hora_entrada: [],
    sortColumn: { path: "hora", order: "asc" },
    searchQuery: "",
    user: {},
    isPresente: [], // 2020-10-15
    videos: [], // 2021-01-15
    exibePaginaInfo: null, // 2021-01-19
    visitouPaginaInfo: null, // 2021-01-19
    news: [], // 2022-09-01
  };

  async componentDidMount() {
    //2021-04-06
    //const user = auth.getCurrentUser(); // 1. Is there a token in the storage?
    this.setState({ user });

    // 2. If so, get the user's  data
    //2023-10-18
    //if (user) {
    if (user && !user.isOnlyQRCode) {
      const { data } = await getSalas();
      const salas = [{ _id: "", name: "Todos os espaços" }, ...data];

      const { data: horarios } = await getHorarios(this.state.selectedItem);
      this.setState({ salas, horarios });

      this.setState({ salas, horarios });

      //> 2022-04-16
      if (horarios.length === 0) {
        if (window.location.pathname === "/horarios/0") {
          //2022-09-19
          //toast.info('Não existem horários/aulas para reserva.')
          toast.info("Não é possível efetuar reservas para hoje.");
        } else {
          //2022-09-19
          //toast.info('Não é possível efetuar reservas para amanhã. Estaremos encerrados.');
          toast.info("Não é possível efetuar reservas para amanhã.");
        }
      }
      //< 2022-04-16

      // 2020-10-15 Verifica/atualiza se está presente ou não no ginásio.
      const { data: isPresente } = await getCheckin(user.cod_atleta);
      this.setState({ isPresente });
      // 2020-10-15

      // 2021-01-15
      if (user.customerAbreviatura === "LIFECLUB") {
        // Em 2023-05-17, desativar NEWS e reativar VIDEOS
        // /* 2022-09-01
        const { data: videos } = await getVideos();
        //const { data: videos } = await getVideos1(); // 2023-03-10
        this.setState({ videos });
        // */

        /* 2023-05-17 desativar NEWS
        const { data: news } = await getNews(); // 2022-09-01
        //const { data: news } = await getNews1(); // 2023-03-10
        this.setState({ news }); // 2022-09-01
        2023-05-17 */
      }

      // createdAt 2021-01-18
      // modifiedAt 2021-04-04
      this.setState({ exibePaginaInfo: false });
      /* 2021-04-04
      if (user.customerAbreviatura === "LIFECLUB") {
        const { data: exibePaginaInfo } = await getExibePaginaInfo();
        this.setState({
          exibePaginaInfo: exibePaginaInfo[0].info === false ? false : true,
        });
        const { data: visitouPaginaInfo } = await getVisitouPaginaInfo(
          user.cod_atleta
        );
        this.setState({
          visitouPaginaInfo: visitouPaginaInfo.length > 0 ? true : false,
        });
      } else {
        this.setState({ exibePaginaInfo: false });
      }
      */
    }
  }

  //2021-01-19
  handleVisitouPaginaInfo = async () => {
    this.setState({ visitouPaginaInfo: true });
  };

  handleSelect = async (item) => {
    //qrcode
    if (item._id === -1) return;

    this.setState({ selectedItem: item._id });

    const { data: horarios } = await getHorarios(item._id);
    this.setState({ horarios });

    //> 2022-04-16
    if (horarios.length === 0) {
      if (item._id === 0) {
        toast.info("Não existem horários/aulas para reserva.");
      } else {
        toast.info(
          //"Não é possível efetuar reservas para amanhã. Estaremos encerrados."
          "Não é possível efetuar reservas para amanhã."
        );
      }
    }
    //< 2022-04-16

    // 2020-10-15 Verifica/atualiza se o utilizador está presente ou não no ginásio.
    const { data: isPresente } = await getCheckin(this.state.user.cod_atleta);
    this.setState({ isPresente });
    // 2020-10-15
  };

  handleSort = (sortColumn) => {
    console.log(sortColumn); // 2020-06-12--
    this.setState({ sortColumn });
  };

  handleSearchQuery = (query) => {
    this.setState({ searchQuery: query });
  };

  handleReserva = async (h) => {
    if (!h.cod_presenca) {
      if (h.reser + h.conf === h.limite) {
        //2020-07-30
        //toast.warning("O horário seleccionado está esgotado!");
        toast.info("O horário seleccionado está esgotado.");
        return;
      }

      //2020-06-12
      //await postReserva(h);
      const { data: resposta } = await postReserva(h);
      if (resposta[1][0].cod_presenca === 0)
        //2020-07-30toast.warning(resposta[1][0].msg);
        toast.info(resposta[1][0].msg);
      else toast("Reserva efetuada com sucesso.");
    } else {
      //2020-06-12
      //await anulaReserva(h);
      const { data: resposta } = await anulaReserva(h);
      console.log("r", resposta[0][0].cod_presenca);
      if (resposta[0][0].cod_presenca === 0)
        //2020-07-30
        //toast.warning("Ocorreu uma anomalia no cancelamento");
        toast.info(resposta[0][0].msg);
      else toast("Reserva cancelada com sucesso.");
    }

    const { data: horarios } = await getHorarios();
    this.setState({ horarios });

    // 2020-10-15 Verifica/atualiza se o utilizador está presente ou não no ginásio.
    const { data: isPresente } = await getCheckin(this.state.user.cod_atleta);
    this.setState({ isPresente });
    // 2020-10-15

    /* 2020-06-12
    if (!h.cod_presenca) toast("Reserva efetuada com sucesso.");
    else toast("Reserva cancelada com sucesso.");
    */
  };

  //2020-06-11
  handleConfirmaReserva = async (h) => {
    console.log("handleConfirmaReserva:", h);

    // 2020-10-15
    // await confirmaReserva(h);
    const { data: resposta } = await confirmaReserva(h);

    if (resposta[0][0].cod_presenca === 0) toast.info(resposta[0][0].msg);
    else toast("Senha emitida com sucesso.");
    // 2020-10-15

    const { data: horarios } = await getHorarios();
    this.setState({ horarios });

    // 2020-10-15 Verifica/atualiza se o utilizador está presente ou não no ginásio.
    const { data: isPresente } = await getCheckin(this.state.user.cod_atleta);
    this.setState({ isPresente });
    // 2020-10-15

    // 2020-10-15
    // toast("Senha emitida com sucesso.");
  };

  getFilteredData = () => {
    const { horarios: allHorarios, searchQuery, sortColumn } = this.state;

    let filtered = allHorarios;

    if (searchQuery)
      filtered = allHorarios.filter((h) =>
        h.abreviatura.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const horarios = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    return { data: horarios };
  };

  render() {
    const { data: horarios } = this.getFilteredData();

    return (
      <div>
        {/*2021-01-19 <NavBarNew user={this.state.user} flag={this.state.semaforo} onItemSelect={this.handleSelect} />*/}
        <NavBarNew
          user={this.state.user}
          exibePaginaInfo={this.state.exibePaginaInfo}
          visitouPaginaInfo={this.state.visitouPaginaInfo}
          onItemSelect={this.handleSelect}
        />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* 2023-02-02 */}
        {user &&
          // user.customerAbreviatura === "LIFECLUB" && ( // user.customerAbreviatura === "ANONYMOUS" && (
          // user.customerAbreviatura === "ANONYMOUS" && ( // user.customerAbreviatura === "LIFECLUB" && (
          user.customerAbreviatura === "LIFECLUB" && ( // user.customerAbreviatura === "ANONYMOUS" && (
            <div
              style={{
                "background-color": "rgb(252, 252, 252)",
                border: "solid",
                "border-radius": "5px",
                padding: 0,
                margin: "0px 15px 15px 15px",
              }}
            >
              <p style={{ padding: 0, margin: "0px 5px" }}>
                {/*Em primeiro lugar queremos desejar-lhe um excelente ano de 2003
                e reiterar a nossa vontade em continuar a contribuir para a sua
                saúde e bem-estar.*/}
                Estimado Membro,
              </p>{" "}
              <p style={{ padding: 0, margin: "0px 5px" }}>
                {/*O mundo mudou tanto nestes últimos meses!*/}
                {/*Informamos que para continuar a garantir a qualidade e variedade
                dos nossos serviços e tendo em consideração a atual conjetura
                económica,{" "}
                <strong>
                  a partir da sua mensalidade de Fevereiro vemo-nos forçados a
                  reajustar o valor das mensalidades em aproximadamente 5%.
                </strong>*/}
                Informamos que a partir da sua mensalidade de março, os preços
                sofrerão uma atualização em função da inflação.
              </p>{" "}
              <p style={{ padding: 0, margin: "0px 5px" }}>
                Este reajuste permitirá continuar a garantir a qualidade do
                serviço e da nossa equipa de profissionais.
              </p>{" "}
              <p style={{ padding: 0, margin: "0px 5px" }}>
                {/*Vimos por este meio comunicar, que{" "}
                <strong>
                  a partir de 1 de março de 2023, haverá um reajuste na sua
                  mensalidade de 7,8%{" "}
                </strong>
              , para acompanhar a subida de taxa de inflação do país.*/}
                Agradecemos a sua compreensão e por continuar a confiar no nosso
                trabalho.
              </p>{" "}
              <p style={{ padding: 0, margin: "0px 5px" }}>
                {/*Contamos com a sua compreensão e, em caso de dúvida, estaremos à
                sua disposição, através do e-mail info@lifeclub.pt ou na receção
                do clube.*/}
                Desejamos a todos um Excelente Ano de 2025.
              </p>
            </div>
          )}
        <div className="App">
          <Switch>
            <Route
              path="/horarios/:id"
              // createdAt 2021-01-15
              // modifiedAt 2021-01-15
              //component={Covid}
              render={(props) => (
                <Horarios
                  horarios={horarios}
                  isPresente={this.state.isPresente} // 2020-10-15
                  sortColumn={this.state.sortColumn}
                  searchQuery={this.searchQuery}
                  onSort={this.handleSort}
                  onChangeQuery={this.handleSearchQuery}
                  onReserva={this.handleReserva}
                  onConfirmaReserva={this.handleConfirmaReserva}
                  {...props}
                />
              )}
            />
            <Route
              path="/horarios"
              render={(props) => (
                <Horarios
                  horarios={horarios}
                  isPresente={this.state.isPresente} // 2020-10-15
                  sortColumn={this.state.sortColumn}
                  searchQuery={this.searchQuery}
                  onSort={this.handleSort}
                  onChangeQuery={this.handleSearchQuery}
                  onReserva={this.handleReserva}
                  onConfirmaReserva={this.handleConfirmaReserva}
                  {...props}
                />
              )}
            />
            <Route path="/login" component={LoginForm} />
            <Route path="/logout" component={Logout} />
            {/* 2021-01-19 */}
            <Route
              path="/main"
              render={(props) => (
                <PaginaInfo
                  exibePaginaInfo={this.state.exibePaginaInfo}
                  visitouPaginaInfo={this.state.visitouPaginaInfo}
                  onVisitouPaginaInfo={this.handleVisitouPaginaInfo}
                  {...props}
                />
              )}
            />
            <Route
              path="/periodos"
              exact
              render={(props) => (
                <ListGroupLinks
                  items={this.state.periodos}
                  selectedItem={this.state.selectedItem}
                  onItemSelect={this.handleSelect}
                  {...props}
                />
              )}
            />
            {/* 2023-02-21 */}
            <Route path="/qrcode" component={CodigoQR} />
            <Route path="/registar" component={RegisterForm} />
            <Route path="/start" component={Start} />
            {/*2021-01-15*/}
            <Route
              path="/videos"
              exact
              render={(props) => (
                <Videos videos={this.state.videos} {...props} />
              )}
            />
            {/*2021-01-15*/}
            <Route path="/video/:id" component={VideoPlayer} />
            {/*2022-09-01*/}
            <Route
              path="/news"
              exact
              render={(props) => <News news={this.state.news} {...props} />}
            />
            {/*2021-01-19 !user && <Redirect from="/" exact to="/login" /> */}
            !user && <Redirect from="/" exact to="/login" />
            {/*2021-04-06 !user ? (
              <Redirect from="/" exact to="/login" />
            ) : (
              <Redirect from="/" exact to="/main" />
            )*/}
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
